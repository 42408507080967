<template>
  <el-tooltip
    :open-delay="700"
    effect="dark"
    :content="$t('content.object_last_state_move')"
    placement="bottom"
    :offset="2"
    :visible-arrow="false"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        fill="white"
      />
      <path
        d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM12 18V13.2H6V10.8H12V6L18 12L12 18Z"
        fill="#F7CA5A"
      />
    </svg>
  </el-tooltip>
</template>

<script>
export default {
  name: 'MoveYellowIcon'
}
</script>
